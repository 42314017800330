import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`

  body {
    color: #333;
    background-color: #000;
  }
  .nav-link:hover {
  color: black !important;
}
`;

export default GlobalStyles;






        


        



   



        
